import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { RestProvider } from '../../../../providers/rest/rest';
import { NotifierService } from 'angular-notifier';

@Component({
  selector: 'app-contact',
  templateUrl: './howItWork.component.html',
  styleUrls: ['./howItWork.component.css']
})
export class howItWorkComponent implements OnInit {
  data:any = {email:'',name:'',subject:'',message:''};
  loading:any = false;
  private readonly notifier: NotifierService;
  constructor(notifierService: NotifierService, public RestProvider: RestProvider,private route: ActivatedRoute,private router: Router) {
  	this.notifier = notifierService;
  }

  ngOnInit() {
  }

  send(){
  	this.loading = true;
	this.RestProvider.email(this.data).subscribe(
      (result:any) => {
        // console.log(result)
  		this.loading = false;
  		this.notifier.notify( 'success', "Message has been sent" );
    },
    (err) => {
  		this.loading = false;
      // console.log(err)
    });
  }
}
