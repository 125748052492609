import { Component, OnInit } from '@angular/core';
import { RestProvider } from './../../../../providers/rest/rest';
import { ActivatedRoute } from '@angular/router';
import { NotifierService } from 'angular-notifier';
import {Router} from '@angular/router';
import {NgbModal, ModalDismissReasons} from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-detail',
  templateUrl: './detail.component.html',
  styleUrls: ['./detail.component.css']
})
export class DetailComponent implements OnInit {
  data:any = {title:'',jobs:[],applied:[]};
  data_payout: any;
  chat:any = {message:''} ;
  skills = [];
  loading:any = false;
  contentloading:any = false;
  completeloading:any = false;
  me:any = localStorage.getItem('name');
  star1:any;
  star2:any;
  star3:any;
  star4:any;
  star5:any;
  rat = 5;
  review = '----';
  closeResult = '';
  diselect = true;
  dataReqPayment = {job_name: '', fee: 0, rek_number: '', name: '', role: 'speaker'};
  disabled = true;
  private readonly notifier: NotifierService;

  constructor(notifierService: NotifierService,public RestProvider: RestProvider,private route:ActivatedRoute,private router: Router, private modalService: NgbModal,) {
    this.notifier = notifierService;
  }

  ngOnInit() {
    this.getData(this.route.snapshot.params.id);
    this.get_payout_reference();
  }
  getData(id){
    this.contentloading = true;
    this.RestProvider.getDetailWorkspace(id).subscribe(
      (result:any) => {
        // console.log(result.data.jobs)
        this.data = result.data;
        this.skills = result.data.jobs.skills.split(',')
        this.contentloading = false;
    },
    (err) => {
      // console.log(err)
        this.contentloading = false;
    });
  }
  
  get_payout_reference(){
    this.contentloading = true;
    this.RestProvider.get_payout_reference().subscribe(
      (result:any) => {
        // console.log(result.data.jobs)
        this.data_payout = result.data;
        this.contentloading = false;
    },
    (err) => {
      // console.log(err)
        this.contentloading = false;
    });
  }

  name(data){
    let name = data.split('/');
    return name[name.length - 1];
  }
  send(data){
    this.loading = true;
    data.applied = this.data.applied.id_applied;
    if (this.data.user == this.data.applied.user && this.data.role == 'worker') {
      data.chat_to = this.data.owner
    }else{
      data.chat_to = this.data.user;
    }
    this.RestProvider.saveChat(data).subscribe(
      (result:any) => {
        // console.log(result);
        this.getData(this.route.snapshot.params.id);
        data.message = null;
        this.loading = false;
    },
    (err) => {
      // console.log(err)
        this.loading = false;
    });
  }
  complete(status){
    this.completeloading = true;
    console.log(this.data.role);
    debugger
    if(this.data.role == 'owner'){
      this.dataReqPayment.role = 'client'
    }
    
    if (status == 2 || status == 6) {
      this.RestProvider.addPayoutRequest(this.dataReqPayment).subscribe(
        (result:any) => {
          // console.log(result);
          this.notifier.notify( 'success', 'Payment Request Created !!');
          this.completeloading = false;
      },
      (err) => {
        // console.log(err)
          this.completeloading = false;
      });
    }
    
    let input = [{id:this.data.applied.id_applied, status: status}];
    // console.log(input)

    this.RestProvider.completeJob(input).subscribe(
      (result:any) => {
        // console.log(result);
        this.getData(this.route.snapshot.params.id);
        this.completeloading = false;
    },
    (err) => {
      // console.log(err)
        this.completeloading = false;
    });
  }

  check_payment(id_invoice){
    this.RestProvider.check(id_invoice).subscribe(
      (result:any) => {
        console.log(result);
        debugger
        if(result.data.status == "PENDING"){
          this.notifier.notify( 'info', 'you haven`t paid for this job');
        }else if(result.data.status == "SETTLED"){
          this.notifier.notify( 'success', 'You Already Paid For this job');
          this.getData(this.route.snapshot.params.id);
        }else{
          this.notifier.notify( 'error', 'Your Invoice Has been EXPIRED');
        }
        
        this.completeloading = false;
    },
    (err) => {
      // console.log(err)
        this.notifier.notify( 'error', 'Error Check Your Internet Connection and try again');
        this.completeloading = false;
    });
  }

  rating(jml){
    this.rat = jml;
    if (jml == 1) {
      this.star1 = 'color: orange';
      this.star2 = '';
      this.star3 = '';
      this.star4 = '';
      this.star5 = '';
    }else if(jml == 2){
      this.star1 = 'color: orange';
      this.star2 = 'color: orange';
      this.star3 = '';
      this.star4 = '';
      this.star5 = '';
    }else if(jml == 3){
      this.star1 = 'color: orange';
      this.star2 = 'color: orange';
      this.star3 = 'color: orange';
      this.star4 = '';
      this.star5 = '';
    }else if(jml == 4){
      this.star1 = 'color: orange';
      this.star2 = 'color: orange';
      this.star3 = 'color: orange';
      this.star4 = 'color: orange';
      this.star5 = '';
    }else if(jml == 5){
      this.star1 = 'color: orange';
      this.star2 = 'color: orange';
      this.star3 = 'color: orange';
      this.star4 = 'color: orange';
      this.star5 = 'color: orange';
    }
  }

  postReview(type){
    this.contentloading = false;
    // 1 client, 2 speakers
    var revData;
    if (type == 1) {
      revData = {
        'post': this.data.post,
        'user': this.data.user,
        'rating': this.rat,
        'review': this.review,
        'reviewer': this.data.jobs.user.id_user
      };  
    }else{
      revData = {
        'post': this.data.post,
        'user': this.data.jobs.user.id_user,
        'rating': this.rat,
        'review': this.review,
        'reviewer': 'me'
      };
    }
    
    console.log(revData);
    this.RestProvider.addReview(revData).subscribe(
      (result:any) => {
        console.log(result.result)
        this.getData(this.route.snapshot.params.id)
        this.notifier.notify( 'success', 'Your Review was successfully submited');
        this.contentloading = false;
    },
    (err) => {
      // console.log(err)
        this.contentloading = false;
        this.notifier.notify( 'error', err);
    });
  }

  openPayout(content) {
    this.modalService.open(content, {ariaLabelledBy: 'modal-basic-title'}).result.then((result) => {
      this.closeResult = `Closed with: ${result}`;
    }, (reason) => {
      this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
    });
  }

  private getDismissReason(reason: any): string {
    if (reason === ModalDismissReasons.ESC) {
      return 'by pressing ESC';
    } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
      return 'by clicking on a backdrop';
    } else {
      return `with: ${reason}`;
    }
  }

  payout_select(value){
    // console.log(value);
    var exp = value.split(',');
    console.log(exp[0]);
    this.disabled = false;
    this.dataReqPayment.rek_number = exp[0]+' '+exp[1];
    this.dataReqPayment.fee = this.data.applied.offer - (this.data.applied.offer * 10/100);
    this.dataReqPayment.job_name = this.data.jobs.title;
    this.dataReqPayment.name = exp[2];

    console.log(this.dataReqPayment);
  }

  open(content) {
    this.modalService.open(content, {ariaLabelledBy: 'modal-basic-title'}).result.then((result) => {
      this.closeResult = `Closed with: ${result}`;
    }, (reason) => {
      this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
    });
  }
}
