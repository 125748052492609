import { HttpClient,HttpHeaders  } from '@angular/common/http';
import { Injectable } from '@angular/core';

/*
  Generated class for the RestProvider provider.

  See https://angular.io/guide/dependency-injection for more info on providers
  and Angular DI.
*/
@Injectable()
export class RestProvider {
  baseUrl:string = "https://office.speakprof.com/api/";
  // baseUrl:string = "http://localhost/speakproff/backend/public/api/";
  key:string = 'd7dcb6c4b71c2e4323410766352e883b';
  headers = new HttpHeaders({ 'Authorization': localStorage.getItem('token') });
  constructor(public http: HttpClient) {
    // console.log('Hello RestProvider Provider');
  }
  getBaseUrl(){
    return this.baseUrl;
  }
  getCategory() {
    return this.http.get( this.baseUrl + 'category');
  }

  getPost(id = '') {
    return this.http.get( this.baseUrl + 'post/index/'+id);
  }
  getCari(id = '') {
    return this.http.get( this.baseUrl + 'post/cari/'+id);
  }
  getDetail(id) {
    if(localStorage.getItem('token') !== null){
      var token = localStorage.getItem('token');
    }else{
      var token = '';
    }
    var headers = new HttpHeaders({'Authorization' : token});
    return this.http.get( this.baseUrl + 'post/detail/'+id, { headers: headers });
  }
  getMyJobs() {
    var headers = new HttpHeaders({'Authorization' : localStorage.getItem('token')});
    return this.http.get( this.baseUrl + 'post/mine',{ headers: headers });
  }
  getWorkspace() {
    var headers = new HttpHeaders({'Authorization' : localStorage.getItem('token')});
    return this.http.get( this.baseUrl + 'applied',{ headers: headers });
  }
  getInvoice(id = null) {
    var headers = new HttpHeaders({'Authorization' : localStorage.getItem('token')});
    if (id) {
      return this.http.get( this.baseUrl + 'invoice/index/'+id,{ headers: headers });
    }else{
      return this.http.get( this.baseUrl + 'invoice',{ headers: headers });
    }
  }
  getDetailWorkspace(id) {
    var headers = new HttpHeaders({'Authorization' : localStorage.getItem('token')});
    return this.http.get( this.baseUrl + 'applied/index/'+id,{ headers: headers });
  }
  getApplied() {
    var headers = new HttpHeaders({'Authorization' : localStorage.getItem('token')});
    return this.http.get( this.baseUrl + 'applied/applied',{ headers: headers });
  }
  getAppliedPost(id) {
    var headers = new HttpHeaders({'Authorization' : localStorage.getItem('token')});
    return this.http.get( this.baseUrl + 'applied/bidder/'+id,{ headers: headers });
  }

  getProfile() {
    var headers = new HttpHeaders({'Authorization' : localStorage.getItem('token')});
    return this.http.get( this.baseUrl + 'user/detail',{ headers: headers });
  }
  getChat() {
    var headers = new HttpHeaders({'Authorization' : localStorage.getItem('token')});
    return this.http.get( this.baseUrl + 'post/chat',{ headers: headers });
  }
  getChatDetail(id) {
    var headers = new HttpHeaders({'Authorization' : localStorage.getItem('token')});
    return this.http.get( this.baseUrl + 'post/chat/'+id,{ headers: headers });
  }
  //POST
  login(data) {
    // console.log(JSON.stringify(data))
    var headers = new HttpHeaders({ 'Content-Type': 'application/json' });
    return this.http.post(this.baseUrl + 'user/login', JSON.stringify(data),{ headers: headers });
  }
  register(data) {
    var headers = new HttpHeaders({ 'Content-Type': 'application/json' });
    return this.http.post(this.baseUrl + 'user', JSON.stringify(data),{ headers: headers });
  }

  register_social(data) {
    var headers = new HttpHeaders({ 'Content-Type': 'application/json' });
    return this.http.post(this.baseUrl + 'user/register_social', JSON.stringify(data),{ headers: headers });
  }
  saveProfile(data, images) {
    console.log(data, images);
    // debugger
    let datas = data;
    data = new FormData();
    data.append('file',images[0]);
    data.append('email', datas.email);
    data.append('username', datas.username);
    data.append('first_name', datas.first_name);
    data.append('last_name', datas.last_name);
    data.append('phone', datas.phone);
    data.append('city', datas.city);
    data.append('country', datas.country);
    data.append('old_img', datas.avatar);

    // console.log(data);
    // debugger
    var headers = new HttpHeaders({ 'Authorization' : localStorage.getItem('token')  });
    return this.http.post(this.baseUrl + 'user/edit', data,{ headers: headers });
  }
  saveOffer(data,images = null) {
    if (images) {
      let datas = data;
      data = new FormData();
      for (var i = 0; i < images.length; ++i) {
        data.append('file[]', images[i]);
      }
      data.append('offer', datas.offer);
      data.append('reason', datas.reason);
      data.append('post', datas.post);
    }
    var headers = new HttpHeaders({ 'Authorization' : localStorage.getItem('token') });
    return this.http.post(this.baseUrl + 'applied',data,{ headers: headers });
  }

  savePost(data,images = null) {
    debugger
    if (images) {
      let datas = data;
      data = new FormData();
      for (var i = 0; i < images.length; ++i) {
        data.append('file[]', images[i]);
      }
      data.append('title', datas.title);
      data.append('description', datas.description);
      data.append('category', datas.category);
      data.append('skills', datas.skills);
      data.append('budget', datas.budget);
      data.append('location', datas.location);
      data.append('deadline', datas.deadline);
    }
    var headers = new HttpHeaders({'Authorization' : localStorage.getItem('token') });
    return this.http.post(this.baseUrl + 'post', data,{ headers: headers });
  }
  saveChat(data) {
    var headers = new HttpHeaders({ 'Content-Type': 'application/json','Authorization' : localStorage.getItem('token') });
    return this.http.post(this.baseUrl + 'post/chat', JSON.stringify(data),{ headers: headers });
  }
  completeJob(data) {
    var headers = new HttpHeaders({ 'Content-Type': 'application/json','Authorization' : localStorage.getItem('token') });
    return this.http.post(this.baseUrl + 'post/completeproject', JSON.stringify(data),{ headers: headers });
  }
  email(data) {
    var headers = new HttpHeaders({ 'Content-Type': 'application/json','Authorization' : 'Basic NmM0N2RiYWZkYTY5YzljOWM0NDYyYzFiZTNkZjg2MmY6N2MyNWFlNGNhMGI1NzNiNzBhZTgwMTM4N2MzY2Q0NTQ=' });
    return this.http.post(this.baseUrl + 'user/email', JSON.stringify(data),{ headers: headers });
  }

  getTesti() {
    if(localStorage.getItem('token') !== null){
      var token = localStorage.getItem('token');
    }else{
      var token = '';
    }
    var headers = new HttpHeaders({'Authorization' : token});
    return this.http.get( this.baseUrl + 'testi',{ headers: headers });
  }

  acceptReject(data) {
    var headers = new HttpHeaders({ 'Content-Type': 'application/json','Authorization' : localStorage.getItem('token') });
    return this.http.post(this.baseUrl + 'applied/accept_reject', JSON.stringify(data),{ headers: headers });
  }
  
  check(id_invoice) {
    // var data = [{id_invoice: id_invoice}]
    var headers = new HttpHeaders({ 'Content-Type': 'application/json','Authorization' : localStorage.getItem('token') });
    return this.http.post(this.baseUrl + 'invoice/check', JSON.stringify(id_invoice),{ headers: headers });
  }

  getListApplied() {
    var headers = new HttpHeaders({'Authorization' : localStorage.getItem('token')});
    return this.http.get( this.baseUrl + 'applied/applied_user', { headers: headers });
  }

  getDetailProfile(id_user) {
    if(localStorage.getItem('token') !== null){
      var token = localStorage.getItem('token');
    }else{
      var token = '';
    }
    var headers = new HttpHeaders({'Authorization' : token});
    return this.http.get( this.baseUrl + 'user/detail_profile/'+id_user, { headers: headers });
  }
  addReview(data) {
    var headers = new HttpHeaders({ 'Content-Type': 'application/json', 'Authorization' : localStorage.getItem('token') });
    return this.http.post(this.baseUrl + 'post/addReview', JSON.stringify(data),{ headers: headers });
  }

  addPayout(data) {
    var headers = new HttpHeaders({ 'Content-Type': 'application/json', 'Authorization' : localStorage.getItem('token') });
    return this.http.post(this.baseUrl + 'user/addPayout', JSON.stringify(data),{ headers: headers });
  }
  
  editPayout(data) {
    var headers = new HttpHeaders({ 'Content-Type': 'application/json', 'Authorization' : localStorage.getItem('token') });
    return this.http.post(this.baseUrl + 'user/editPayout', JSON.stringify(data),{ headers: headers });
  }
  
  deletePayout(data) {
    var headers = new HttpHeaders({ 'Content-Type': 'application/json', 'Authorization' : localStorage.getItem('token') });
    return this.http.post(this.baseUrl + 'user/deletePayout', JSON.stringify(data),{ headers: headers });
  }

  getPaymentMethodList() {
    if(localStorage.getItem('token') !== null){
      var token = localStorage.getItem('token');
    }else{
      var token = '';
    }
    var headers = new HttpHeaders({'Authorization' : token});
    return this.http.get( this.baseUrl + 'invoice/get_payment_list', { headers: headers });
  }
  
  getSubPaymentMethodList(type) {
    if(localStorage.getItem('token') !== null){
      var token = localStorage.getItem('token');
    }else{
      var token = '';
    }
    var headers = new HttpHeaders({'Authorization' : token});
    return this.http.get( this.baseUrl + 'invoice/get_sub_payment_list/'+type, { headers: headers });
  }
  
  getPayout() {
    var headers = new HttpHeaders({'Authorization' : localStorage.getItem('token')});
    return this.http.get( this.baseUrl + 'invoice/get_payout', { headers: headers });
  }
  
  get_payout_reference() {
    var headers = new HttpHeaders({'Authorization' : localStorage.getItem('token')});
    return this.http.get( this.baseUrl + 'invoice/get_payout_reference', { headers: headers });
  }

  addPayoutRequest(data) {
    debugger
    var headers = new HttpHeaders({ 'Content-Type': 'application/json', 'Authorization' : localStorage.getItem('token') });
    return this.http.post(this.baseUrl + 'invoice/create_payout', JSON.stringify(data),{ headers: headers });
  }
  
  addPortfolio(data, images) {
    console.log(data, images);
    // debugger
    let datas = data;
    data = new FormData();
    data.append('file',images[0]);
    data.append('title', datas.title);
    var headers = new HttpHeaders({ 'Authorization' : localStorage.getItem('token') });
    return this.http.post(this.baseUrl + 'user/addPortfolio', data,{ headers: headers });
  }
  
  editPortfolio(data, images) {
    console.log(data, images);
     // debugger
    let datas = data;
    data = new FormData();
    if (images) {
      data.append('file',images[0]);
      data.append('title', datas.title);
      data.append('id_portfolio', datas.id_portfolio);
    }else{
      data.append('title', datas.title);
      data.append('id_portfolio', datas.id_portfolio);
    }
    
    var headers = new HttpHeaders({ 'Authorization' : localStorage.getItem('token') });
    return this.http.post(this.baseUrl + 'user/editPortfolio', data,{ headers: headers });
  }
  
  deletePortfolio(data) {
    var headers = new HttpHeaders({ 'Content-Type': 'application/json', 'Authorization' : localStorage.getItem('token') });
    return this.http.post(this.baseUrl + 'user/deletePortfolio', JSON.stringify(data),{ headers: headers });
  }
  
  getNotif() {
    var headers = new HttpHeaders({'Authorization' : localStorage.getItem('token')});
    return this.http.get( this.baseUrl + 'user/notif', { headers: headers });
  }
  
  readNotif(data) {
    var headers = new HttpHeaders({'Authorization' : localStorage.getItem('token')});
    return this.http.post( this.baseUrl + 'user/read_notif', data,{ headers: headers });
  }
  
  addNotif(data) {
    var headers = new HttpHeaders({'Authorization' : localStorage.getItem('token')});
    return this.http.post( this.baseUrl + 'user/add_notif', data,{ headers: headers });
  }

  get_about() {
    if(localStorage.getItem('token') !== null){
      var token = localStorage.getItem('token');
    }else{
      var token = '';
    }
    var headers = new HttpHeaders({'Authorization' : token});
    return this.http.get( this.baseUrl + 'testi/about', { headers: headers });
  }

  get_privacy() {
    if(localStorage.getItem('token') !== null){
      var token = localStorage.getItem('token');
    }else{
      var token = '';
    }
    var headers = new HttpHeaders({'Authorization' : token});
    return this.http.get( this.baseUrl + 'testi/privacy', { headers: headers });
  }
  
  get_terms() {
    if(localStorage.getItem('token') !== null){
      var token = localStorage.getItem('token');
    }else{
      var token = '';
    }
    var headers = new HttpHeaders({'Authorization' : token});
    return this.http.get( this.baseUrl + 'testi/terms', { headers: headers });
  }
  
  get_faq() {
    if(localStorage.getItem('token') !== null){
      var token = localStorage.getItem('token');
    }else{
      var token = '';
    }
    var headers = new HttpHeaders({'Authorization' : token});
    return this.http.get( this.baseUrl + 'testi/faq', { headers: headers });
  }
  
  reset_password(data) {
    if(localStorage.getItem('token') !== null){
      var token = localStorage.getItem('token');
    }else{
      var token = '';
    }
    var headers = new HttpHeaders({'Authorization' : token});
    return this.http.post( this.baseUrl + 'user/forgot_password',data ,{ headers: headers });
  }

  check_invoice() {
    var token = localStorage.getItem('token');

    var headers = new HttpHeaders({'Authorization' : token});
    return this.http.get( this.baseUrl + 'invoice/checkAll', { headers: headers });
  }

  newsletter(data){
    console.log(data)
    debugger
    if(localStorage.getItem('token') !== null){
      var token = localStorage.getItem('token');
    }else{
      var token = '';
    }
    var headers = new HttpHeaders({'Authorization' : token});
    return this.http.post( this.baseUrl + 'user/newsletter', data,{ headers: headers });
  }

}
