import { Component, OnInit, ElementRef, ViewChild } from '@angular/core';
import { RestProvider } from './../../../../providers/rest/rest';
import { NotifierService } from 'angular-notifier';
import { SocialAuthService, SocialUser } from "angularx-social-login";
import { FacebookLoginProvider, GoogleLoginProvider } from "angularx-social-login";
import {NgbModal} from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css']
})
export class LoginComponent implements OnInit {
  @ViewChild('content') content: ElementRef;
  private user: SocialUser;
  closeResult = '';
  signin:any = {email:'',password:''};
  loading:any = false;
  data:any = {first_name:'', last_name:'', avatar:'', username: '', email:'', role: 2, phone: '081234567890', password: 'Hp7SyigAvr8rjXnbqY1QjjiLckHtQlxoMstM4BtC', repassword: 'Hp7SyigAvr8rjXnbqY1QjjiLckHtQlxoMstM4BtC'};
  role = 'client';
  private readonly notifier: NotifierService;
  constructor(private modalService: NgbModal, notifierService: NotifierService,public RestProvider: RestProvider,  private authService: SocialAuthService,) {
    this.notifier = notifierService;
  }

  ngOnInit() {
    this.authService.authState.subscribe((user) => {
      this.user = user;
      // localStorage.setItem('name',user.firstName);
      // localStorage.setItem('avatar',user.photoUrl);
      // localStorage.setItem('token',user.authToken);
      // window.location.href = "/home"

      // console.log('USER', user);
    })}


  signinform(){
    this.loading = true;
    this.RestProvider.login(this.signin).subscribe(
      (result:any) => {
        // console.log(result)
        this.loading = false;
        localStorage.setItem('token',result.token);
        localStorage.setItem('name',result.name);
        localStorage.setItem('avatar',result.avatar);
        localStorage.setItem('provider',result.provider);
        window.location.href = "/profile/"+result.id_user
    },
    (err) => {
      // console.log(err)
      this.loading = false;
      this.notifier.notify( 'error', err.error.invalid );
    });
  }

  signInGoogle(){
    this.authService.signIn(GoogleLoginProvider.PROVIDER_ID);
    this.authService.authState.subscribe((user) => {
      this.user = user;
      this.data.email = user.email;

      this.RestProvider.login(this.data).subscribe(
        (result:any) => {
          console.log(result)
          debugger
          this.loading = false;
          localStorage.setItem('token',result.token);
          localStorage.setItem('name',result.name);
          localStorage.setItem('avatar',result.avatar);
          localStorage.setItem('provider',result.provider);
          window.location.href = "/profile/"+result.id_user
      },
      (err) => {
        // console.log(err)
        this.loading = false;
        this.open(this.content)
      });
      
    })
  }
  signInWithFB(): void {
    this.authService.signIn(FacebookLoginProvider.PROVIDER_ID);
    this.authService.authState.subscribe((user) => {
      this.user = user;
      this.data.email = user.email;

      this.RestProvider.login(this.data).subscribe(
        (result:any) => {
          this.loading = false;
          localStorage.setItem('token',result.token);
          localStorage.setItem('name',result.name);
          localStorage.setItem('avatar',result.avatar);
          localStorage.setItem('provider',result.provider);
          window.location.href = "/profile/"+result.id_user
      },
      (err) => {
        this.loading = false;
        // this.notifier.notify( 'error', "You Are Not Registered" );
        this.open(this.content)
      });
    })
    
  }

  registerSocial(){
    console.log(this.role)
    var user = this.user
    if (this.role == 'client') {
      this.data.role = 1;
    }else{
      this.data.role = 2;
    }

    this.data.first_name = user.firstName;
    this.data.username = user.firstName.toLowerCase()+'_'+Math.floor(Math.random() * 1000);
    this.data.last_name = user.lastName;
    this.data.avatar = user.photoUrl;
    this.data.email = user.email;

    this.RestProvider.register_social(this.data).subscribe(
      (result:any) => {
        this.loading = false;
        var login = {email: this.data.email, password: 'Hp7SyigAvr8rjXnbqY1QjjiLckHtQlxoMstM4BtC'};
        this.RestProvider.login(login).subscribe(
          (result:any) => {
            this.loading = false;
            localStorage.setItem('token',result.token);
            localStorage.setItem('name',result.name);
            localStorage.setItem('avatar',result.avatar);
            localStorage.setItem('provider',result.provider);
            window.location.href = "/profile/"+result.id_user
        },
        (err) => {
          this.loading = false;
          // this.notifier.notify( 'error', "Please check your email for confirmation" );
        });
    },
    (err) => {
      this.loading = false;
      this.notifier.notify( 'error', "Register Failed" );
    });
  }

  changeRole(role){
    this.role = role;
  }

  open(content) {
    this.modalService.open(content, {ariaLabelledBy: 'modal-basic-title'}).result.then((result) => {
      this.closeResult = `Closed with: ${result}`;
    }, (reason) => {
      this.closeResult = `Dismissed`;
    });
  }
}
