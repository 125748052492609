import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { RestProvider } from './../../../../providers/rest/rest';
import { NotifierService } from 'angular-notifier';

@Component({
  selector: 'app-workspace',
  templateUrl: './workspace.component.html',
  styleUrls: ['./workspace.component.css']
})
export class WorkspaceComponent implements OnInit {
  data:any
  private readonly notifier: NotifierService;
  loading:any;
  constructor(notifierService: NotifierService, public RestProvider: RestProvider,private route: ActivatedRoute,private router: Router) {
  	this.notifier = notifierService;
  }

  ngOnInit() {
  	this.getData();
  }
  getData(){
    this.loading = true;
    this.RestProvider.getWorkspace().subscribe(
      (result:any) => {
        // console.log(result)
        this.data = result.data;
        this.loading = false;
    },
    (err) => {
      // console.log(err)
      this.loading = false;
    });

    this.RestProvider.check_invoice().subscribe(
      (result:any) => {
        console.log("CHEK BERHASIL")
    },
    (err) => {
      console.log(err)
    });
    
  }
}
