import { Component, OnInit } from '@angular/core';
import { RestProvider } from './../../../../providers/rest/rest';
import {ActivatedRoute, Router} from '@angular/router';

@Component({
  selector: 'app-jobs',
  templateUrl: './jobs.component.html',
  styleUrls: ['./jobs.component.css']
})
export class JobsComponent implements OnInit {
  data:any;
  dataCategory:any;
  category:any;
  loading:any = false;
  q:any;
  filter = true;
  id:any;
  mobile:any = false;
  constructor(public RestProvider: RestProvider,private route:ActivatedRoute, private router:Router) { }

  ngOnInit() {
    this.filter = true;
    if (this.route.snapshot.params.q) {
      this.getCari(this.route.snapshot.params.q);
    }else{
      this.getData(this.route.snapshot.params.cat);
    }
    this.getCategory();
    if (window.screen.width <= 500) { // 768px portrait
      this.mobile = true;
    }
    console.log(this.mobile)
    console.log("===========")
    console.log("===========")
    console.log("===========")
    console.log("===========")
    console.log("===========")
    console.log("===========")
    console.log("===========")
    console.log("===========")
    console.log("===========")
    console.log("===========")
    console.log("===========")
    console.log("===========")
    console.log("===========")
    console.log(window.screen.width)
  }
  getData(id){
    
    this.loading = true;
    this.filter = true;
    console.log(id)
    this.RestProvider.getPost(id).subscribe(
      (result:any) => {
        // console.log(result)
        this.loading = false;
        this.data = result.data;
        this.category = result.data.skills.split(",");
    },
    (err) => {
        this.loading = false;
      // console.log(err)
    });
  }

  getCategory(){
    this.loading = true;
    this.RestProvider.getCategory().subscribe(
      (result:any) => {
        console.log('resss=>>', result)
        this.dataCategory = result.data;
        this.loading = false;
        console.log('cattt ==>>', this.dataCategory)
    },
    (err) => {
      // console.log(err)
        this.loading = false;
    });
    this.id = this.route.snapshot.params.cat;
  }

  getCari(q){
    this.loading = true;
    this.RestProvider.getCari(q).subscribe(
      (result:any) => {
        // console.log(result)
        this.loading = false;
        this.data = result.data;
    },
    (err) => {
        this.loading = false;
      // console.log(err)
    });
  }

  filterClick(){
    this.filter = !this.filter;
  }

  cari(){
    this.router.navigate(['/cari/'+this.q]);
    // this.filter = !this.filter;
  }

  cek(date){
    var da = new Date();
	  var daa = new Date(date);
    // const dat = Math.ceil(Math.abs(da.getTime() - daa.getTime()) / (1000 * 3600 * 24));
    var Time = da.getTime() - daa.getTime(); 
    var dat = Time / (1000 * 3600 * 24);
    return dat;
  }

  positive(number){
    var hour = Math.abs(number) + 1
    var split = hour.toString().split('.')

    return split[0];
  }

}
