import { Component, OnInit } from '@angular/core';
import { RestProvider } from './../../../../providers/rest/rest';
import { ActivatedRoute } from '@angular/router';
import { NotifierService } from 'angular-notifier';
import {Router} from '@angular/router';

@Component({
  selector: 'app-single',
  templateUrl: './single.component.html',
  styleUrls: ['./single.component.css']
})
export class SingleComponent implements OnInit {
  data:any = {title:''};
  complete = 0;
  skills = [];
  activeOne = 'active';
  activeTwo = 'bg-light text-dark';
  private readonly notifier: NotifierService;

  constructor(notifierService: NotifierService,public RestProvider: RestProvider,private route:ActivatedRoute,private router: Router) {
    this.notifier = notifierService;
  }

  ngOnInit() {
  	this.getData(this.route.snapshot.params.id);
  }
  getData(id){
    this.RestProvider.getDetail(id).subscribe(
      (result:any) => {
        // console.log(result)
        this.data = result.data;
        this.skills = result.data.skills.split(',');
        for (let i = 0; i < this.data.jobs.length; i++) {
          if (this.data.jobs[i].status == 1) {
            this.complete = this.complete+1;
          }
        }
        for (let i = 0; i < this.data.hired.length; i++) {
          if (this.data.hired[i].status > 0) {
            this.complete = this.complete+1;
          }
        }
    },
    (err) => {
      // console.log(err)
    });
  }
  bid(data){
    // console.log(data)
    if (localStorage.getItem('token')) {
      this.router.navigate(['/apply'],{queryParams:{id:data.id_post}});
    }else{
      this.notifier.notify( 'info', 'You need to login to apply this job' );
    }
  }
  name(data){
    let name = data.split('/');
    return name[name.length - 1];
  }
  goto(url){
    window.open(url, '_blank');
  }

  switchTab(tab){
    if (tab == 1) {
      this.activeOne = 'active';
      this.activeTwo = 'bg-light text-dark';
    }else{
      this.activeTwo = 'active';
      this.activeOne = 'bg-light text-dark';
    }
  }
}
