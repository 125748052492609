import { Component, OnInit } from '@angular/core';
import { RestProvider } from './../../../../providers/rest/rest';
import { ActivatedRoute } from '@angular/router';
// import { NotifierService } from 'angular-notifier';
import {Router} from '@angular/router';
import { NotifierService } from 'angular-notifier';
import {NgbModal, ModalDismissReasons} from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-profile',
  templateUrl: './profile.component.html',
  styleUrls: ['./profile.component.css']
})
export class ProfileComponent implements OnInit {
  data:any;
  closeResult = '';
  activeOne = 'active';
  activeTwo = 'bg-light text-dark';
  activeThree = 'bg-light text-dark';

  hrOne = 'bg-primary';
  hrTwo = '';
  hrThree = '';

  dataAdd = {title: ''};
  dataEdit = {id_portfolio: 0, title: ''};
  imageData:any;
  selectedImage:any;
  private readonly notifier: NotifierService;
  constructor(private modalService: NgbModal, notifierService: NotifierService, public RestProvider: RestProvider,private route:ActivatedRoute,private router: Router) {
    this.notifier = notifierService;
  }

  ngOnInit() {
    this.getData(this.route.snapshot.params.id)
  }

  getData(id){
    this.RestProvider.getDetailProfile(id).subscribe(
      (result:any) => {
        // console.log(result)
        this.data = result.data;
        console.log(this.data)
        if(result.data.role == 1){
          this.switchTab(2)
        }
    },
    (err) => {
      // console.log(err)
    });

    this.RestProvider.check_invoice().subscribe(
      (result:any) => {
        console.log("CHEK BERHASIL")
    },
    (err) => {
      console.log(err)
    });
    
  }

  switchTab(tab){
    if (tab == 1) {
      this.activeOne = 'active';
      this.activeTwo = 'bg-light text-dark';
      this.activeThree = 'bg-light text-dark';
      this.hrOne = 'bg-primary';
      this.hrTwo = '';
      this.hrThree = '';
    }else if(tab == 2){
      this.activeTwo = 'active';
      this.activeOne = 'bg-light text-dark';
      this.activeThree = 'bg-light text-dark';
      this.hrOne = '';
      this.hrTwo = 'bg-primary';
      this.hrThree = '';
    }else{
      this.activeThree = 'active';
      this.activeOne = 'bg-light text-dark';
      this.activeTwo = 'bg-light text-dark';
      this.hrOne = '';
      this.hrTwo = '';
      this.hrThree = 'bg-primary';
    }
  }

  openAdd(content) {
    this.modalService.open(content, {ariaLabelledBy: 'modal-basic-title'}).result.then((result) => {
      this.closeResult = `Closed with: ${result}`;
    }, (reason) => {
      this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
    });
  }

  openEdit(item, content1) {
    this.dataEdit.title = item.title;
    this.dataEdit.id_portfolio = item.id_portfolio;
    this.modalService.open(content1, {ariaLabelledBy: 'modal-basic-title'}).result.then((result) => {
      this.closeResult = `Closed with: ${result}`;
    }, (reason) => {
      this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
    });
  }

  private getDismissReason(reason: any): string {
    if (reason === ModalDismissReasons.ESC) {
      return 'by pressing ESC';
    } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
      return 'by clicking on a backdrop';
    } else {
      return `with: ${reason}`;
    }
  }

  onFileChange(event){
    this.imageData = event.target.files;
    this.selectedImage = event.target.files[0].name;
    // console.log(this.imageData, '----', this.data)
  }

  addPortfolio(){
    this.RestProvider.addPortfolio(this.dataAdd, this.imageData).subscribe(
      (result:any) => {
        this.getData(this.route.snapshot.params.id)
        this.notifier.notify( 'success', "Portfolio Succesfully Uploaded" );
    },
    (err) => {
      console.log(err)
    });
  }
  
  editPortfolio(){
    this.RestProvider.editPortfolio(this.dataEdit, this.imageData).subscribe(
      (result:any) => {
        this.getData(this.route.snapshot.params.id)
        this.notifier.notify( 'success', "Portfolio Succesfully Updated" );
    },
    (err) => {
      console.log(err)
    });
  }
  
  deletePortfolio(data){
    var id = {
      'id_portfolio': data.id_portfolio
    };
    this.RestProvider.deletePortfolio(id).subscribe(
      (result:any) => {
        this.getData(this.route.snapshot.params.id)
        this.notifier.notify( 'success', "Portfolio Succesfully Deleted" );
    },
    (err) => {
      console.log(err)
    });
  }

}
