import { Component, OnInit } from '@angular/core';
import { RestProvider } from './../../../../providers/rest/rest';
import { NotifierService } from 'angular-notifier';
import { SocialAuthService, SocialUser } from "angularx-social-login";
import { FacebookLoginProvider, GoogleLoginProvider } from "angularx-social-login";
import {ActivatedRoute} from '@angular/router';
import {Country} from '@angular-material-extensions/select-country'; 

@Component({
  selector: 'app-registernow',
  templateUrl: './registernow.component.html',
  styleUrls: ['./registernow.component.css']
})
export class RegisternowComponent implements OnInit {
  private user: SocialUser;
  defaultCountry: Country = {
    name: 'Indonesia',
    alpha2Code: 'ID',
    alpha3Code: 'IND',
    numericCode: '276'
 };
  register:any = {email:'',password:'',username:'',phone:'', country: 'Indonesian', city: ''};
  loading:any = false;
  data:any = {first_name:'', last_name:'', avatar:'', username: '', email:'', role: 2, phone: '081234567890', password: 'Hp7SyigAvr8rjXnbqY1QjjiLckHtQlxoMstM4BtC', repassword: 'Hp7SyigAvr8rjXnbqY1QjjiLckHtQlxoMstM4BtC'};
  private readonly notifier: NotifierService;
  constructor(notifierService: NotifierService, private authService: SocialAuthService, public RestProvider: RestProvider,private route:ActivatedRoute) {
    this.notifier = notifierService;
  }

  ngOnInit() {
    this.authService.authState.subscribe((user) => {
      this.user = user;
    })
  }
  registerForm(){
  	// console.log("hallo")
  	if (this.route.snapshot.params.type == 'client') {
  		this.register.role = 1;
  	}else{
  		this.register.role = 2;
  	}
    this.loading = true;
    this.RestProvider.register(this.register).subscribe(
      (result:any) => {
        // console.log(result)
        this.loading = false;
        this.notifier.notify( 'success', "Success, Please check your email and confirm your registration");
        setTimeout(function(){
          window.location.href = "/login"
        }, 2500);
    },
    (err) => {
      // console.log(err)
      this.loading = false;
      this.notifier.notify( 'error', err.error.message );
    });
  }

  registerGoogle(){
    this.authService.signIn(GoogleLoginProvider.PROVIDER_ID);
    this.authService.authState.subscribe((user) => {
      this.user = user;
      if (this.route.snapshot.params.type == 'client') {
        this.data.role = 1;
      }else{
        this.data.role = 2;
      }

      this.data.first_name = user.firstName;
      this.data.username = user.firstName.toLowerCase()+'_'+user.id;
      this.data.last_name = user.lastName;
      this.data.avatar = user.photoUrl;
      this.data.email = user.email;

      this.RestProvider.register_social(this.data).subscribe(
        (result:any) => {
          // console.log(result)
          this.loading = false;
          // localStorage.setItem('token',result.token);
          // this.notifier.notify( 'success', "Please check your email and confirm your registration");
          // window.location.href = "/login"
          var login = {email: this.data.email, password: 'Hp7SyigAvr8rjXnbqY1QjjiLckHtQlxoMstM4BtC'};
          this.RestProvider.login(login).subscribe(
            (result:any) => {
              this.loading = false;
              localStorage.setItem('token',result.token);
              localStorage.setItem('name',result.name);
              localStorage.setItem('avatar',result.avatar);
              localStorage.setItem('provider',result.provider);
              window.location.href = "/profile/"+result.id_user
          },
          (err) => {
            this.loading = false;
            this.notifier.notify( 'error', "Please check your email for confirmation" );
          });
      },
      (err) => {
        this.loading = false;
        var login = {email: this.data.email, password: 'Hp7SyigAvr8rjXnbqY1QjjiLckHtQlxoMstM4BtC'};
        this.RestProvider.login(login).subscribe(
          (result:any) => {
            this.loading = false;
            localStorage.setItem('token',result.token);
            localStorage.setItem('name',result.name);
            localStorage.setItem('avatar',result.avatar);
            localStorage.setItem('provider',result.provider);
            window.location.href = "/profile/"+result.id_user
        },
        (err) => {
          this.loading = false;
          this.notifier.notify( 'error', "Please check your email for confirmation" );
        });
      });
    })
  }

  registerFacebook(){
    this.authService.signIn(FacebookLoginProvider.PROVIDER_ID);
    this.authService.authState.subscribe((user) => {
      this.user = user;
      if (this.route.snapshot.params.type == 'client') {
        this.data.role = 1;
      }else{
        this.data.role = 2;
      }

      this.data.first_name = user.firstName;
      this.data.username = user.firstName.toLowerCase()+'_'+user.id;
      this.data.last_name = user.lastName;
      this.data.avatar = user.photoUrl;
      this.data.email = user.email;

      this.RestProvider.register_social(this.data).subscribe(
        (result:any) => {
          // console.log(result)
          this.loading = false;
          // localStorage.setItem('token',result.token);
          // this.notifier.notify( 'success', "Please check your email and confirm your registration");
          // window.location.href = "/login"
          var login = {email: this.data.email, password: 'Hp7SyigAvr8rjXnbqY1QjjiLckHtQlxoMstM4BtC'};
          this.RestProvider.login(login).subscribe(
            (result:any) => {
              this.loading = false;
              localStorage.setItem('token',result.token);
              localStorage.setItem('name',result.name);
              localStorage.setItem('avatar',result.avatar);
              localStorage.setItem('provider',result.provider);
              window.location.href = "/profile/"+result.id_user
          },
          (err) => {
            this.loading = false;
            this.notifier.notify( 'error', "Please check your email for confirmation" );
          });
      },
      (err) => {
        this.loading = false;
        var login = {email: this.data.email, password: 'Hp7SyigAvr8rjXnbqY1QjjiLckHtQlxoMstM4BtC'};
        this.RestProvider.login(login).subscribe(
          (result:any) => {
            this.loading = false;
            localStorage.setItem('token',result.token);
            localStorage.setItem('name',result.name);
            localStorage.setItem('avatar',result.avatar);
            localStorage.setItem('provider',result.provider);
            window.location.href = "/profile/"+result.id_user
        },
        (err) => {
          this.loading = false;
          this.notifier.notify( 'error', "Please check your email for confirmation" );
        });
      });
    })
  }

  onCountrySelected(country: Country) {
    console.log(country);
    this.register.country = country.name;
  }
}
