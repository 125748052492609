import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { RestProvider } from './../../../../providers/rest/rest';
import { NotifierService } from 'angular-notifier';

@Component({
  selector: 'app-contact',
  templateUrl: './contact.component.html',
  styleUrls: ['./contact.component.css']
})
export class ContactComponent implements OnInit {
  data:any = {email:'',name:'',subject:'',message:''};
  loading:any = false;
  disabled = false;
  private readonly notifier: NotifierService;
  constructor(notifierService: NotifierService, public RestProvider: RestProvider,private route: ActivatedRoute,private router: Router) {
  	this.notifier = notifierService;
  }

  ngOnInit() {
  }

  send(){
  	this.loading = true;
  	this.disabled = true;
	  this.RestProvider.email(this.data).subscribe(
      (result:any) => {
        // console.log(result)
  		this.loading = false;
  		this.notifier.show({
        type: 'success',
        message: "Message has been sent"
      });
      setTimeout(function(){
        window.location.href = '/';
      }, 2000);
    },
    (err) => {
  		this.loading = false;
      // console.log(err)
    });
  }
}
