import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { RestProvider } from './../../../../providers/rest/rest';
import { NotifierService } from 'angular-notifier';
import {NgbModal, ModalDismissReasons} from '@ng-bootstrap/ng-bootstrap';
// declare var $: any;
@Component({
  selector: 'app-setting',
  templateUrl: './setting.component.html',
  styleUrls: ['./setting.component.css']
})
export class SettingComponent implements OnInit {
  data:any;
  dataMethod:any;
  dataSubMethod:any;
  closeResult = '';
  private readonly notifier: NotifierService;
  loading:any;
  loginLast = new Date();
  selectedImg:any = '';
  imageData: any = [];
  dataEdit = {id_payout: '', method: '', sub_method: '', details: '', number: 0, name: ''};
  dataAdd = {method: '', sub_method: '', number: '0', name: ''};
  disabled = true;
  constructor(private modalService: NgbModal, notifierService: NotifierService, public RestProvider: RestProvider,private route: ActivatedRoute,private router: Router) {
  	this.notifier = notifierService;
  }
  ngOnInit() {
    this.getData();
    this.get_payment_list();
  }
  getData(){
    this.RestProvider.getProfile().subscribe(
      (result:any) => {
        // console.log(result)
        this.data = result.data;
        console.log(this.data);
    },
    (err) => {
      // console.log(err)
    });

    this.RestProvider.check_invoice().subscribe(
      (result:any) => {
        console.log("CHEK BERHASIL")
    },
    (err) => {
      console.log(err)
    });
    
  }
  
  get_payment_list(){
    this.RestProvider.getPaymentMethodList().subscribe(
      (result:any) => {
        // console.log(result)
        this.dataMethod = result.data;
        // console.log("methodd===>>", this.dataMethod);
    },
    (err) => {
      // console.log(err)
    });
  }

  getSubMethod(type){
    this.dataEdit.method = type.options[type.options.selectedIndex].text;
    this.dataAdd.method = type.options[type.options.selectedIndex].text;
    // console.log("texttt", type.options[type.options.selectedIndex].text)
    this.RestProvider.getSubPaymentMethodList(type.value).subscribe(
      (result:any) => {
        // console.log(result)
        this.dataSubMethod = result.data;
        // console.log("methodd===>>", this.dataSubMethod);
    },
    (err) => {
      // console.log(err)
    });
  }

  onFileChange(event){
    this.imageData = event.target.files;
      this.selectedImg = event.target.files[0].name;
      this.disabled = false;
    // console.log(this.imageData, '----', this.data)
    var reader = new FileReader();

    reader.onload = (event: ProgressEvent) => {
      this.data.avatar = (<FileReader>event.target).result;
    }

    reader.readAsDataURL(event.target.files[0]);
  }
  save(data){
    this.loading= true;
    this.RestProvider.saveProfile(data, this.imageData).subscribe(
      (result:any) => {
        // console.log(result)
        this.loading= false;
        localStorage.setItem('avatar',result.avatar);
        this.notifier.notify( 'success', "Update profile berhasil" );
        
        this.getData();
        if(this.selectedImg != ''){
          window.location.reload()
        }

        this.selectedImg = '';
    },
    (err) => {
        this.loading= false;
      // console.log(err)
    });
  }

  openAdd(content1) {
    this.modalService.open(content1, {ariaLabelledBy: 'modal-basic-title'}).result.then((result) => {
      this.closeResult = `Closed with: ${result}`;
    }, (reason) => {
      this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
    });
  }

  openEdit(item, content2) {
    this.dataEdit.id_payout = item.id_payout;
    // this.dataEdit.method = item.method;
    // this.dataEdit.sub_method = item.sub_method;
    this.dataEdit.number = item.number;
    this.dataEdit.name = item.name;

    this.modalService.open(content2, {ariaLabelledBy: 'modal-basic-title'}).result.then((result) => {
      this.closeResult = `Closed with: ${result}`;
    }, (reason) => {
      this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
    });
  }

  private getDismissReason(reason: any): string {
    if (reason === ModalDismissReasons.ESC) {
      return 'by pressing ESC';
    } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
      return 'by clicking on a backdrop';
    } else {
      return `with: ${reason}`;
    }
  }

  addPayout(data){
    // console.log(data)
    // debugger
    this.loading= true;
    this.RestProvider.addPayout(data).subscribe(
      (result:any) => {
        // console.log(result)
        this.loading= false;
        this.dataAdd = {method: '', sub_method: '', number: '0', name: ''};
        this.notifier.notify( 'success', "Add Payout Success" );
        this.getData();
    },
    (err) => {
        this.loading= false;
      // console.log(err)
    });
  }
  
  editPayout(id){
    this.loading= true;
    // this.dataEdit.id_payout = id;
    var edit = {
      'id_payout': id,
      'method': this.dataEdit.method,
      'sub_method': this.dataEdit.sub_method,
      'number': this.dataEdit.number,
      'name': this.dataEdit.name
    }
    // debugger
    this.RestProvider.editPayout(edit).subscribe(
      (result:any) => {
        // console.log(result)
        this.loading= false;
        this.notifier.notify( 'success', "Update Payout Success" );
        this.getData();
    },
    (err) => {
        this.loading= false;
      // console.log(err)
    });
  }
  
  deletePayout(id){
    this.loading= true;
    var data = {'id_payout': id};
    this.RestProvider.deletePayout(data).subscribe(
      (result:any) => {
        // console.log(result)
        this.loading= false;
        this.notifier.notify( 'success', "Payout Deleted" );
        this.getData();
    },
    (err) => {
        this.loading= false;
      // console.log(err)
    });
  }

  change(){
    console.log('yaa')
    this.disabled = false
  }
}
