import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { RestProvider } from './../../../../providers/rest/rest';
import { NotifierService } from 'angular-notifier';
import {NgbModal, ModalDismissReasons} from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-bidder',
  templateUrl: './bidder.component.html',
  styleUrls: ['./bidder.component.css']
})
export class BidderComponent implements OnInit {
  closeResult = '';
  data:any;
  dataStatus:any = [];
  private readonly notifier: NotifierService;
  redirect = false;
  loading:any;
  constructor(private modalService: NgbModal, notifierService: NotifierService, public RestProvider: RestProvider,private route: ActivatedRoute,private router: Router) {
    this.notifier = notifierService;
  }

  ngOnInit() {
    this.getData(this.route.snapshot.params.id);
    // console.log(this.data);
    console.log('urll==>>', this.router.url)
  }

  getData(id){
    this.loading = true;
    this.RestProvider.getAppliedPost(id).subscribe((result:any) => {
      this.data = result.data;
      this.loading = false;
      // console.log(this.data[0].attachment);
    }, (err) => {
      this.loading = false;
    })
  }

  name(data){
    let name = data.split('/');
    return name[name.length - 1];
  }

  acceptReject(data, status){
    // console.log(data.post)
    // debugger
    this.redirect = true;

    this.dataStatus = [{
      'id_user': data.id_user,
      'status': status,
      'id_post': this.route.snapshot.params.id,
      'id_applied': data.id_applied,
      'email': data.email,
      'amount': data.offer,
      'first_name': data.first_name,
      'user': data.user
    }];
    this.RestProvider.acceptReject(this.dataStatus).subscribe((result:any) => {
      if (status == 1) {
        // debugger
        var notif = {
          'message': 'You are accepted for the job',
          'notification_for': data.user,
          'link': '/dashboard/workspace'
        };
        
        this.RestProvider.addNotif(notif).subscribe(() => {
          console.log('ok')
        });
        
        this.getData(this.route.snapshot.params.id);
        window.open(result.data.invoice_url, '_blank');
        this.redirect = false;
      }else{
        this.getData(this.route.snapshot.params.id);
        this.redirect = false;
      }
    })
  }

  open(content) {
    this.modalService.open(content, {ariaLabelledBy: 'modal-basic-title'}).result.then((result) => {
      this.closeResult = `Closed with: ${result}`;
    }, (reason) => {
      this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
    });
  }

  openReject(content2) {
    this.modalService.open(content2, {ariaLabelledBy: 'modal-basic-title'}).result.then((result) => {
      this.closeResult = `Closed with: ${result}`;
    }, (reason) => {
      this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
    });
  }

  private getDismissReason(reason: any): string {
    if (reason === ModalDismissReasons.ESC) {
      return 'by pressing ESC';
    } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
      return 'by clicking on a backdrop';
    } else {
      return `with: ${reason}`;
    }
  }

}
