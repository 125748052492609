import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { RestProvider } from './../../../../providers/rest/rest';
import { NotifierService } from 'angular-notifier';

@Component({
  selector: 'app-applied',
  templateUrl: './applied.component.html',
  styleUrls: ['./applied.component.css']
})
export class AppliedComponent implements OnInit {
  data:any
  private readonly notifier: NotifierService;
  loading:any;
  selectedIndex = -1;
  selectrue = false;
  constructor(notifierService: NotifierService, public RestProvider: RestProvider,private route: ActivatedRoute,private router: Router) {
  	this.notifier = notifierService;
  }

  ngOnInit() {
  	this.getData();
  }

  showContent(evt, index) {
    this.selectedIndex = index;
    this.selectrue = !this.selectrue;
  }
  getData(){
    this.loading = true;
    this.RestProvider.getListApplied().subscribe(
      (result:any) => {
        // console.log(result)
        this.data = result.data;
        this.loading = false;
    },
    (err) => {
      // console.log(err)
        this.loading = false;
    });
  }
}
