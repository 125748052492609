import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { RestProvider } from './../../../../providers/rest/rest';
import { NotifierService } from 'angular-notifier';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.css']
})
export class HeaderComponent implements OnInit {
  isLogin:any = false;
  avatar:any;
  user:any = {role:''};
  notif:any;
  isMenuCollapsed = true;
  constructor(notifierService: NotifierService, public RestProvider: RestProvider,private route: ActivatedRoute,private router: Router) {  }

  ngOnInit() {
    this.getNotif();

  	if (localStorage.getItem('token')) {
  		this.isLogin = true;
      this.avatar = localStorage.getItem('avatar');
  	}
    this.RestProvider.getProfile().subscribe(
      (result:any) => {
        this.user = result.data;
    },
    (err) => {
      console.log(err)
    });
    
    this.RestProvider.check_invoice().subscribe(
      (result:any) => {
        console.log("CHEK BERHASIL")
    },
    (err) => {
      console.log(err)
    });
  }

  getNotif(){
    this.RestProvider.getNotif().subscribe(
      (result:any) => {
        this.notif = result.data;
    },
    (err) => {
      console.log(err)
    });
  }

  read(id){
    var data = {
      'idx': id
    };
    this.RestProvider.readNotif(data).subscribe(
      (result:any) => {
        this.getNotif();
    },
    (err) => {
      console.log(err)
    });
  }
  logout(){
  	localStorage.clear();
  	window.location.href = '/home'
  }

}
