import { Component, OnInit } from '@angular/core';
import { RestProvider } from '../../../../providers/rest/rest';

@Component({
  selector: 'app-faq',
  templateUrl: './faq.component.html',
  styleUrls: ['./faq.component.css']
})
export class FaqComponent implements OnInit {
  data:any;
  loading = false;
  constructor(public RestProvider: RestProvider) { }

  ngOnInit(): void {
    this.getData();
  }

  getData(){
    this.RestProvider.get_faq().subscribe(
      (result:any) => {
        // console.log("aboutttt", result)
        if(result.data.length == 0){
          this.data.question = 'No Data';
          this.data.answer = 'No Data';
        }
        this.data =result.data;
      this.loading = false;
    },
    (err) => {
      this.loading = false;
      // console.log(err)
    });
  }

  show(index){
    if (this.data[index].show == 'show') {
      this.data[index].show = '';
    }else{
      this.data[index].show = 'show'
    }
  }
}
