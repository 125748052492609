import { Component, OnInit } from '@angular/core';
import { RestProvider } from './../../../../providers/rest/rest';
import { NotifierService } from 'angular-notifier';
import { Router, NavigationEnd } from '@angular/router';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.css']
})
export class FooterComponent implements OnInit {
  name: string;
  year:any = new Date().getFullYear();
  about:any;
  content:any;
  data = {email: ''};
  notifier:any;
  constructor(public RestProvider: RestProvider, notifierService: NotifierService, public router: Router) {
    this.notifier = notifierService;
  }

  ngOnInit() {
    this.name = localStorage.getItem('name');
    console.log(this.name);
    this.getAbout()
    
    this.router.events.subscribe((evt) => {
      if (!(evt instanceof NavigationEnd)) {
          return;
      }
      window.scrollTo({
        top:0,
        behavior: 'smooth'
      })
    });
  }

  getAbout(){
    this.RestProvider.get_about().subscribe(
      (result:any) => {
        // console.log(result)
        this.about = result.data;
        
        var p = result.data.content.split(".");
        p[0].replace('</p>', '.')
        p[0].replace('<p>', '.')
        p[0].replace('</h1>', '.')
        p[0].replace('<h1>', '')
        p[0].replace('<div>', '')
        p[0].replace('</div>', '')
        this.content = p[0].replace('</h1>', '.').replace('</div>', '').replace('<div>', ' ');
    },
    (err) => {
      // console.log(err)
    });
  }

  send(){
    console.log(this.data)
    this.notifier.notify( 'success', "Email for newsletter was send");
    this.RestProvider.newsletter(this.data).subscribe(
      (result:any) => {
      },
      (err) => {
        console.log(err)
      });
      window.location.href = '/';
  }

}
