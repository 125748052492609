import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { RestProvider } from '../../../../providers/rest/rest';
import { NotifierService } from 'angular-notifier';

@Component({
  selector: 'app-contact',
  templateUrl: './about.component.html',
  styleUrls: ['./about.component.css']
})
export class AboutComponent implements OnInit {
  data:any = {email:'',name:'',subject:'',message:''};
  dataAbout: any;
  loading:any = false;
  private readonly notifier: NotifierService;
  constructor(notifierService: NotifierService, public RestProvider: RestProvider,private route: ActivatedRoute,private router: Router) {
  	this.notifier = notifierService;
  }

  ngOnInit() {
    this.getData()
  }

  getData(){
    this.RestProvider.get_about().subscribe(
      (result:any) => {
        if(result.data.length == 0){
          this.dataAbout.content = 'No Data';
          this.dataAbout.background = 'https://images.unsplash.com/photo-1582473217199-fe8b6ac09599?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&w=1000&q=80';
        }
        // console.log("aboutttt", result)
        this.dataAbout =result.data;
  		this.loading = false;
    },
    (err) => {
  		this.loading = false;
      // console.log(err)
    });
  }

  send(){
  	this.loading = true;
	this.RestProvider.email(this.data).subscribe(
      (result:any) => {
        // console.log(result)
  		this.loading = false;
  		this.notifier.notify( 'success', "Message has been sent" );
    },
    (err) => {
  		this.loading = false;
      // console.log(err)
    });
  }
}
