import { Component, OnInit } from '@angular/core';
import { RestProvider } from './../../../../providers/rest/rest';
import { ActivatedRoute } from '@angular/router';
import { NotifierService } from 'angular-notifier';
import {Router} from '@angular/router';


@Component({
  selector: 'app-invoicedetail',
  templateUrl: './invoicedetail.component.html',
  styleUrls: ['./invoicedetail.component.css']
})
export class InvoicedetailComponent implements OnInit {
  data:any;
  loading:any = false;
  constructor(notifierService: NotifierService,public RestProvider: RestProvider,private route:ActivatedRoute,private router: Router) {
  	// console.log("PARAMS",this.route.snapshot.params)
  }

  ngOnInit() {
  	this.getData(this.route.snapshot.params.id);
  }
  getData(id){
    this.loading = true;
    this.RestProvider.getInvoice(id).subscribe(
      (result:any) => {
        // console.log(result)
        this.data = result.data;
        this.loading = false;
    },
    (err) => {
      // console.log(err)
        this.loading = false;
    });
  }

}
