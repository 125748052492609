import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';

import { AppComponent } from './app.component';
import { HeaderComponent } from './components/layout/header/header.component';
import { FooterComponent } from './components/layout/footer/footer.component';
import { HomeComponent } from './components/site/home/home.component';
import { RouterModule, Routes } from '@angular/router';
// import { registerContentQuery } from '@angular/core/src/render3/instructions';
import { JobsComponent } from './components/site/jobs/jobs.component';
import { LoginComponent } from './components/site/login/login.component';
import { RegisterComponent } from './components/site/register/register.component';
import { ApplyComponent } from './components/site/apply/apply.component';
import { ContactComponent } from './components/site/contact/contact.component';
import { howItWorkComponent } from './components/site/howItWork/howItWork.component';
import { AboutComponent } from './components/site/About/about.component';
import { RegisternowComponent } from './components/site/registernow/registernow.component';
import { WorkspaceComponent } from './components/dashboard/workspace/workspace.component';
import { InboxComponent } from './components/dashboard/inbox/inbox.component';
import { AppliedComponent } from './components/dashboard/applied/applied.component';
import { ReviewComponent } from './components/dashboard/review/review.component';
import { SettingComponent } from './components/dashboard/setting/setting.component';
import { ProfileComponent } from './components/site/profile/profile.component';
import { NotfoundComponent } from './components/site/notfound/notfound.component';
import { HttpClientModule } from '@angular/common/http';
import { RestProvider } from '../providers/rest/rest';
import { TimeAgoPipe } from 'time-ago-pipe';
import { FroalaEditorModule, FroalaViewModule } from 'angular-froala-wysiwyg';

import { SocialLoginModule, SocialAuthServiceConfig }  from 'angularx-social-login';
import {
  GoogleLoginProvider,
  FacebookLoginProvider,
  AmazonLoginProvider,
} from 'angularx-social-login';

import { SingleComponent } from './components/site/single/single.component';
import { SpinnerComponent } from './components/spinner/spinner.component';
import { SidebarComponent } from './components/dashboard/sidebar/sidebar.component';
import { NotifierModule } from 'angular-notifier';
import { DetailComponent } from './components/dashboard/detail/detail.component';
import { ChatComponent } from './components/dashboard/chat/chat.component';
import { InvoiceComponent } from './components/dashboard/invoice/invoice.component';
import { JobComponent } from './components/dashboard/job/job.component';
import { InvoicedetailComponent } from './components/dashboard/invoicedetail/invoicedetail.component';
import { CheckoutComponent } from './components/dashboard/checkout/checkout.component';
import { CreatejobComponent } from './components/dashboard/createjob/createjob.component';
import { BidderComponent } from './components/dashboard/bidder/bidder.component';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { PayoutComponent } from './components/dashboard/payout/payout.component';
import { NoopAnimationsModule } from '@angular/platform-browser/animations';
import { MatSelectCountryModule } from '@angular-material-extensions/select-country';
import { MatSelectModule } from "@angular/material/select";
import { MatIconModule } from "@angular/material/icon";
import { MatInputModule } from "@angular/material/input";
import { PrivacyComponent } from './components/site/privacy/privacy.component';
import { TermsComponent } from './components/site/terms/terms.component';
import { FaqComponent } from './components/site/faq/faq.component';
import { ResetComponent } from './components/site/reset/reset.component';


const appRoutes: Routes = [
  { path: '',component: HomeComponent},
  { path: 'home', component: HomeComponent},
  { path: 'jobs', component: JobsComponent},
  { path: 'jobs/:cat', component: JobsComponent},
  { path: 'cari/:q', component: JobsComponent},
  { path: 'login', component: LoginComponent},
  { path: 'register', component: RegisterComponent},
  { path: 'register/:type', component: RegisternowComponent},
  { path: 'apply', component: ApplyComponent},
  { path: 'contact', component: ContactComponent},
  { path: 'how-it-work', component: howItWorkComponent},
  { path: 'about', component: AboutComponent},
  { path: 'privacy', component: PrivacyComponent},
  { path: 'terms', component: TermsComponent},
  { path: 'faq', component: FaqComponent},
  { path: 'detail/:id', component: SingleComponent},
  { path: 'reset', component: ResetComponent},
  //Dashboard
  { path: 'dashboard/workspace', component: WorkspaceComponent},
  { path: 'dashboard/workspace/:id', component: DetailComponent},
  { path: 'dashboard/inbox', component: InboxComponent},
  { path: 'dashboard/applied', component: AppliedComponent},
  { path: 'dashboard/chat/:id', component: ChatComponent},
  { path: 'dashboard/review', component: ReviewComponent},
  { path: 'dashboard/setting', component: SettingComponent},
  { path: 'dashboard/jobs', component: JobComponent},
  { path: 'dashboard/invoice', component: InvoiceComponent},
  { path: 'dashboard/invoice/:id', component: InvoicedetailComponent},
  { path: 'dashboard/checkout/:id', component: CheckoutComponent},
  { path: 'dashboard/create', component: CreatejobComponent},
  { path: 'dashboard/payout', component: PayoutComponent},
  { path: 'profile/:id', component: ProfileComponent},
  { path: 'dashboard/bidder/:id', component: BidderComponent},
  { path: 'head', component: HeaderComponent},
  {path: '404', component: NotfoundComponent},
  {path: '**', redirectTo: '/404'}
];
@NgModule({
  declarations: [
    AppComponent,
    howItWorkComponent,
    HeaderComponent,
    FooterComponent,
    HomeComponent,
    JobsComponent,
    LoginComponent,
    RegisterComponent,
    ApplyComponent,
    ContactComponent,
    AboutComponent,
    RegisternowComponent,
    WorkspaceComponent,
    InboxComponent,
    AppliedComponent,
    ReviewComponent,
    SettingComponent,
    ProfileComponent,
    NotfoundComponent,
    TimeAgoPipe,
    SingleComponent,
    SpinnerComponent,
    SidebarComponent,
    DetailComponent,
    ChatComponent,
    InvoiceComponent,
    JobComponent,
    InvoicedetailComponent,
    CheckoutComponent,
    CreatejobComponent,
    BidderComponent,
    PayoutComponent,
    PrivacyComponent,
    TermsComponent,
    FaqComponent,
    ResetComponent,
  ],
  imports: [
    BrowserModule,
    SocialLoginModule,
    HttpClientModule,
    FormsModule,
    MatInputModule,
    FroalaEditorModule.forRoot(),
    FroalaViewModule.forRoot(),
    MatSelectCountryModule.forRoot('en'),
    NotifierModule.withConfig({position: {
    horizontal: {
      position: 'right',
      distance: 12
    },
    vertical: {
      position: 'top',
      distance: 12,
      gap: 10
    }
  }}),
    RouterModule.forRoot(appRoutes),
    NgbModule,
    NoopAnimationsModule
  ],
  providers: [
    {
      provide: 'SocialAuthServiceConfig',
      useValue: {
        autoLogin: false,
        providers: [
          {
            id: GoogleLoginProvider.PROVIDER_ID,
            provider: new GoogleLoginProvider(
              '208064632235-uuerm0s69qgoshj67evml2m81r3rmrt2.apps.googleusercontent.com'
            ),
          },
          {
            id: FacebookLoginProvider.PROVIDER_ID,
            provider: new FacebookLoginProvider('265872574758807'),
            // provider: new FacebookLoginProvider('360697708692444'),
          },
        ],
      } as SocialAuthServiceConfig,
    },
    RestProvider
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
