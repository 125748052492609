import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { RestProvider } from './../../../../providers/rest/rest';
import { NotifierService } from 'angular-notifier';

@Component({
  selector: 'app-sidebar',
  templateUrl: './sidebar.component.html',
  styleUrls: ['./sidebar.component.css']
})
export class SidebarComponent implements OnInit {
  user:any = {role:''};
  isCollapsed:boolean = false;
  constructor(notifierService: NotifierService, public RestProvider: RestProvider,private route: ActivatedRoute,private router: Router) {
  	this.RestProvider.getProfile().subscribe(
      (result:any) => {
        // console.log("side barr==>>>",result.data)
        this.user = result.data;
    },
    (err) => {
      // console.log(err)
    });
  }

  ngOnInit() {
  }

  logout() {
    localStorage.clear();
  	//this.router.navigateByUrl('/home');
  	window.location.href = '/home'
  }

  collapse(){
    this.isCollapsed = !this.isCollapsed
  }
}
