import { Component, OnInit } from '@angular/core';
import { RestProvider } from './../../../../providers/rest/rest';
import { ActivatedRoute } from '@angular/router';
import { NotifierService } from 'angular-notifier';
import {Router} from '@angular/router';


@Component({
  selector: 'app-createjob',
  templateUrl: './createjob.component.html',
  styleUrls: ['./createjob.component.css']
})
export class CreatejobComponent implements OnInit {
  create:any = {description:''};
  categories:any;
  skills: any;
  skillselected = [];
  categoryselected = [];
  fileData: any;
  loading:any = false;
  private readonly notifier: NotifierService;
  constructor(notifierService: NotifierService,public RestProvider: RestProvider,private route:ActivatedRoute,private router: Router) {
  	this.notifier = notifierService;
  }
  fileProgress(fileInput: any) {
    // console.log(fileInput.target.files)
    this.fileData = fileInput.target.files;
    // console.log(this.fileData)
  }
  ngOnInit() {
    this.RestProvider.getCategory().subscribe(
      (result:any) => {
        // console.log(result)
        this.categories = result.data;
    },
    (err) => {
      // console.log(err)
    });
  }
  submit(data){
    console.log(data)
    debugger
    this.loading = true;
    this.RestProvider.savePost(data,this.fileData).subscribe(
      (result:any) => {
        // console.log(result);
        data.message = null;
        this.notifier.notify( 'success', result.message );
	    this.router.navigate(['/dashboard/jobs']);
        this.loading = false;
    },
    (err) => {
      // console.log(err)
        this.loading = false;
    });
  }

  // addCategory(cat){
  //   this.categoryselected.indexOf(cat) === -1 ? this.categoryselected.push(cat) : console.log('sama');
    
  //   this.create.categoryselected = this.categoryselected;
  //   console.log(this.create)
  // }

  // removeCategory(cat, i){
  //   this.categoryselected.indexOf(cat) > -1 ? this.categoryselected.splice(i, 1) : console.log('sama');
  //   this.create.categoryselected = this.categoryselected;
  //   console.log(this.create)
  // }

  addSkill(){
    var dataskill = this.skills.split(",");
    console.log(dataskill);
    if (this.skillselected.length < 10) {
      for (let i = 0; i < dataskill.length; i++) {
        this.skillselected.indexOf(dataskill[i]) === -1 ? this.skillselected.push(dataskill[i]) : console.log("already add");
      }
    }
    this.create.skills = this.skillselected.join();
    this.skills = '';
  }

  removeSkills(skill, i){
    this.skillselected.indexOf(skill) > -1 ? this.skillselected.splice(i, 1) : console.log('kosong');
    this.create.skills = this.skillselected.join();
    console.log(this.create)
  }
}
