import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { RestProvider } from './../../../../providers/rest/rest';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.css']
})
export class HomeComponent implements OnInit {
  data:any;
  loading:any = false;
  q:any;
  testi = [];
  testi1 = [];
  testi2 = [];
  constructor(public RestProvider: RestProvider,private route: ActivatedRoute,private router: Router) {
    this.getData();
    this.getTesti();
  }

  ngOnInit() {
    // this.getTesti()
  }
  getData(){
    this.loading = true;
    this.RestProvider.getCategory().subscribe(
      (result:any) => {
        // console.log(result)
        this.data = result.data;
        this.loading = false;
    },
    (err) => {
      // console.log(err)
        this.loading = false;
    });

    this.RestProvider.check_invoice().subscribe(
      (result:any) => {
        console.log("CHEK BERHASIL")
    },
    (err) => {
      console.log(err)
    });
    
  }

  getTesti(){
    this.loading = true;
    this.RestProvider.getTesti().subscribe(
      (result:any) => {
        for (let i = 0; i < result.data.length; i++) {
          if(i < 4){
            this.testi.push(result.data[i]);
          }else if(i > 3 && i < 8){
            this.testi1.push(result.data[i]);
          }
        }
        this.loading = false;
    },
    (err) => {
      // console.log(err)
        this.loading = false;
    });
  }

  cari(){
    this.router.navigate(['/cari/'+this.q]);
  }
}
