import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { RestProvider } from './../../../../providers/rest/rest';
import { NotifierService } from 'angular-notifier';

@Component({
  selector: 'app-invoice',
  templateUrl: './invoice.component.html',
  styleUrls: ['./invoice.component.css']
})
export class InvoiceComponent implements OnInit {
  data:any
  private readonly notifier: NotifierService;
  loading:any;
  user:any;
  constructor(notifierService: NotifierService, public RestProvider: RestProvider,private route: ActivatedRoute,private router: Router) {
    this.RestProvider.getProfile().subscribe(
      (result:any) => {
        // console.log(result)
        this.user = result.data;
    },
    (err) => {
      // console.log(err)
    });
  	this.notifier = notifierService;
  }

  ngOnInit() {
  	this.getData();
  }
  getData(){
    this.loading = true;
    this.RestProvider.getInvoice().subscribe(
      (result:any) => {
        // console.log(result)
        this.data = result.data;
        this.loading = false;
    },
    (err) => {
      // console.log(err)
      this.loading = false;
    });

    this.RestProvider.check_invoice().subscribe(
      (result:any) => {
        console.log("CHEK BERHASIL")
    },
    (err) => {
      console.log(err)
    });
    
  }
  godetail(data){
    this.router.navigate(['/dashboard/invoice/'+data.id_invoice])
  }
}
