import { Component, OnInit } from '@angular/core';
import { RestProvider } from './../../../../providers/rest/rest';
import { NotifierService } from 'angular-notifier';

@Component({
  selector: 'app-reset',
  templateUrl: './reset.component.html',
  styleUrls: ['./reset.component.css']
})
export class ResetComponent implements OnInit {
  private readonly notifier: NotifierService;
  email:any;
  constructor(notifierService: NotifierService, public RestProvider: RestProvider) {
    this.notifier = notifierService;
  }

  ngOnInit(): void {
  }

  reset_password(){
    var data = {
      'email': this.email
    };
    this.RestProvider.reset_password(data).subscribe(
      (result:any) => {
        // console.log(result)
      	this.notifier.notify( 'success', "Please check your email and reset your password");
        window.location.href = "/home"
    },
    (err) => {
      // console.log(err)
      this.notifier.notify('error', 'You haven`t register yet' );
    });
  }

}
