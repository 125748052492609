import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { RestProvider } from './../../../../providers/rest/rest';
import { NotifierService } from 'angular-notifier';

@Component({
  selector: 'app-apply',
  templateUrl: './apply.component.html',
  styleUrls: ['./apply.component.css']
})
export class ApplyComponent implements OnInit {
  data:any;
  offer:any = {offer:'',reason:'',attachment:''};
  private readonly notifier: NotifierService;
  loading:any;
  fileData: any;
  constructor(notifierService: NotifierService, public RestProvider: RestProvider,private route: ActivatedRoute,private router: Router) {
  	this.notifier = notifierService;
  }
  fileProgress(fileInput: any) {
    // console.log(fileInput.target.files)
    this.fileData = fileInput.target.files;
  }

  ngOnInit() {
  	this.route
      .queryParams
      .subscribe(params => {
        // Defaults to 0 if no query param provided.
				this.offer.post = params.id;
        this.getData(params.id);
      });
  }
  getData(id){
    this.RestProvider.getDetail(id).subscribe(
      (result:any) => {
        // console.log(result)
        this.offer.offer = result.data.budget;
        this.data = result.data;
    },
    (err) => {
      // console.log(err)
    });
  }
  ganti(searchValue: any): void {
	  this.offer.offer = searchValue * 100/90;
	}
	handleFileInput(files: FileList) {
    this.offer.attachment = files;
	}
	submit(offer){
		if (offer.reason.length < 1) {
			this.notifier.notify( 'error', "Description cannot be empty" );
		}else{
			this.loading = true;
			this.RestProvider.saveOffer(offer,this.fileData).subscribe(
	      (result:any) => {
	        // console.log(result)
	        this.loading = false;
	        this.notifier.notify( 'success', result.message );
	        this.router.navigate(['/detail/'+this.offer.post]);
	    },
	    (err) => {
	      // console.log(err)
	      this.loading = false;
	      this.notifier.notify( 'error', err.data.message );
	    });
		}
	}
}
