import { Component, OnInit } from '@angular/core';
import { RestProvider } from '../../../../providers/rest/rest';

@Component({
  selector: 'app-terms',
  templateUrl: './terms.component.html',
  styleUrls: ['./terms.component.css']
})
export class TermsComponent implements OnInit {
  data:any;
  loading = false;
  constructor(public RestProvider: RestProvider) { }

  ngOnInit(): void {
    this.getData();
  }

  getData(){
    this.RestProvider.get_terms().subscribe(
      (result:any) => {
        // console.log("aboutttt", result)
        if(result.data.length == 0){
          this.data.content = 'No Data';
        }
        this.data =result.data;
  		this.loading = false;
    },
    (err) => {
  		this.loading = false;
      // console.log(err)
    });
  }

}
