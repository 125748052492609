import { Component, OnInit } from '@angular/core';
import { RestProvider } from './../../../../providers/rest/rest';
import { ActivatedRoute } from '@angular/router';
import { NotifierService } from 'angular-notifier';
import {Router} from '@angular/router';


@Component({
  selector: 'app-chat',
  templateUrl: './chat.component.html',
  styleUrls: ['./chat.component.css']
})
export class ChatComponent implements OnInit {
	data:any;
  loading:any = false;
  chat:any = {message:''} ;
  temp:any;
  to = '1';
  from = '2';
  me:any = localStorage.getItem('name');
  constructor(notifierService: NotifierService,public RestProvider: RestProvider,private route:ActivatedRoute,private router: Router) { }

  ngOnInit() {
  	this.route
      .queryParams
      .subscribe(params => {
        // Defaults to 0 if no query param provided.
        this.temp = params;
        // this.getData(this.route.snapshot.params.id);
      });
  	this.getData(this.route.snapshot.params.id);
  }
  getData(id){
    this.loading = true;
    this.RestProvider.getChatDetail(id).subscribe(
      (result:any) => {
        console.log(result)
        this.data = result.data;
        this.loading = false;
        for (let i = 0; i < result.data.length; i++) {
          if (result.data[i].me == false) {
            this.to = result.data[i].user.id_user;
          }
          if (result.data[i].me == true) {
            this.from = result.data[i].user.id_user;
          }
        }
        // debugger
    },
    (err) => {
      // console.log(err)
        this.loading = false;
    });
  }
  send(data){
    this.loading = true;
    data.applied = this.route.snapshot.params.id;
    data.chat_from = this.from;
    data.chat_to = this.to;
    // debugger
    this.RestProvider.saveChat(data).subscribe(
      (result:any) => {
        // console.log(result);
        this.getData(this.route.snapshot.params.id);
        data.message = null;
        this.loading = false;
    },
    (err) => {
      // console.log(err)
        this.loading = false;
    });
  }
}
